import {get} from "@/axios";
const base = process.env.VUE_APP_API_ROOT;

/**
 * 获取pdf的logo,公章等信息
 * @param {{programId}} programId
 * @returns {Promise<*>}
 */
export const findAiTaxLogo = async ({programId}) => {
  const url = `${base}/plat/logo/findAiTaxLogo`;
  const res = await get(url,{programId});
  return res.data;
};
